<script setup lang="ts">
import { PropType, computed, onMounted, ref } from 'vue'
import { Plan } from '@/shared/subscription'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/store/user/userStore'
import { useRouter, useRoute } from 'vue-router'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'

const { plans, monthly } = defineProps({
  plans: Object as PropType<Plan[]>,
  monthly: {
    type: Boolean,
    default: false,
  },
})

const subscriptionStore = useSubscriptionStore()
const userStore = useUserStore()
const router = useRouter()
const route = useRoute()

const priceInteger = (price: number) => {
  return Math.floor(price)
}

const decimalPrice = (price: number) => {
  const decimalPart = (price % 1).toFixed(2).substring(1)
  return decimalPart === '.00' ? '' : decimalPart
}
const { t } = useI18n()

const displayDetail = ref(false)

const redirect = async (id: string) => {
  if (id == 'cabinet') return false

  if (userStore.isLogged) {
    if (
      userStore.user.profilType === 'patient' &&
      route.query.change_role === 'praticien'
    ) {
      return await router.push({
        name: 'Register',
        query: { role: 'praticien', step: '2', change_role: 'praticien' },
      })
    }

    if (userStore.user.profilType == 'practitioner') {
      return await router.push({
        name: 'DashboardAccount',
      })
    } else {
      return await router.push({
        name: 'DashboardAppointmentsPatients',
      })
    }
  } else {
    return await router.push({
      name: 'Register',
      query: { role: 'praticien', step: '1' },
    })
  }
}

const message = computed(() => {
  if (
    !subscriptionStore.subscriptionStatus &&
    !subscriptionStore.subscriptionStatus['type']
  )
    return false

  const tva = subscriptionStore.subscriptionStatus['tva']
  let msg = ''
  let price = null

  // is trialing period
  if (
    subscriptionStore.trialRemainingDays &&
    subscriptionStore.trialRemainingDays > 0
  ) {
    price = subscriptionStore.subscriptionStatus['actualPrice']
  } else {
    // période d'éssai terminé
    // beneficie encore des mois de réduction
    if (
      subscriptionStore.subscriptionStatus['discountMonthsRemaining'] &&
      (subscriptionStore.subscriptionStatus[
        'discountMonthsRemaining'
      ] as number) > 0
    ) {
      price = subscriptionStore.subscriptionStatus['actualPrice']
    } else {
      price = subscriptionStore.subscriptionStatus['price']
    }
  }

  if (
    subscriptionStore.subscriptionStatus['type'] == 'lifetime' ||
    subscriptionStore.subscriptionStatus['type'] == 'life'
  ) {
    msg = `TVA = ${tva}%  soit ${price}€ ${tva ? 'TTC' : 'HT'} facturé en une seule fois.`
  } else {
    msg = `TVA = ${tva}%  soit ${price}€ ${tva ? 'TTC' : 'HT'} facturé ${subscriptionStore.subscriptionStatus['type'] == 'annually' || subscriptionStore.subscriptionStatus['type'] == 'annual' ? 'annuellement' : 'mensuellement'}.`
  }

  return msg
})

const selectedPlan = computed(() => {
  if (subscriptionStore.planChoise && subscriptionStore.planChoise.id) {
    return subscriptionStore.planChoise.id
  }
})
</script>

<template>
  <v-expand-transition>
    <div
      class="d-md-flex ga-md-5 position-relative"
      v-if="selectedPlan && plans[0].id === selectedPlan"
    >
      <v-card
        elavation="0"
        color="white"
        max-width="365"
        min-width="365"
        class="border border-secondary border-md border-opacity-100 pt-10 px-sm-2 rounded-xl flat"
        :class="plans[0].id === 'monthly' ? 'py-sm-8' : 'py-sm-2'"
        style="box-shadow: none !important"
      >
        <v-card-text>
          <div class="d-flex ga-sm-2 ga-1">
            <div
              :class="plans[0].bgIconColor"
              class="d-flex align-center justify-center rounded-lg"
              style="width: 80px; height: 80px"
            >
              <img style="width: 25px; height: 25px" :src="plans[0].icon" />
            </div>
            <div class="d-flex flex-column ga-1">
              <h3 class="" style="font-size: 16px; color: #004838">
                {{ plans[0].title }}
              </h3>
              <p
                v-if="plans[0].subTitle1"
                class="d-flex align-center ga-1"
                style="font-size: 13px"
              >
                <img class="w-4" :src="plans[0].subTitle1.icon" />
                <span>{{ plans[0].subTitle1.title }}</span>
              </p>
              <p
                v-if="plans[0].subTitle2"
                class="d-flex align-center ga-1"
                style="font-size: 13px"
              >
                <img class="w-4" :src="plans[0].subTitle2.icon" />
                <span>{{ plans[0].subTitle2.title }}</span>
              </p>
            </div>
          </div>

          <template v-if="plans[0].id !== 'cabinet'">
            <!-- display default praticien feature -->
            <div class="my-sm-4 my-2" v-if="!displayDetail">
              <div class="my-sm-3 my-2">
                <!-- start discount bloc  -->
                <div class="d-flex align-center">
                  <div class="price font-weight-bold">
                    <span class="price-integer"
                      >€{{
                        priceInteger(
                          subscriptionStore.hasDiscount
                            ? plans[0].price
                            : plans[0].old_price,
                        )
                      }}</span
                    ><span v-if="plans[0].price" class="price-decimal">{{
                      decimalPrice(
                        subscriptionStore.hasDiscount
                          ? plans[0].price
                          : plans[0].old_price,
                      )
                    }}</span>
                  </div>

                  <!-- <div v-else class="price font-weight-bold">
                    <span class="price-integer"
                      >€{{ priceInteger(plans[0].old_price) }}</span
                    ><span v-if="plans[0].old_price" class="price-decimal">{{
                      decimalPrice(plans[0].old_price)
                    }}</span>
                  </div> -->

                  <div
                    class="d-flex"
                    v-if="plans[0].discount && subscriptionStore.hasDiscount"
                  >
                    <div class="d-flex flex-column text-center mr-1">
                      <div
                        v-if="plans[0].old_price"
                        class="custom-strikethrough"
                      >
                        €{{ plans[0].old_price }}
                      </div>
                      <div class="taxe">
                        H.T
                        {{ plans[0].id == 'annual' ? '' : '/Mois' }}
                      </div>
                    </div>

                    <div
                      class="rounded-pill align-self-start py-0.5 px-1 text-white"
                      style="background-color: #ff5a43"
                    >
                      -{{ plans[0].discount }}%
                    </div>
                  </div>
                  <div class="align-self-center pa-0" v-else>H.T</div>
                </div>
                <!-- end discount bloc  -->

                <div
                  style="color: #616161; font-size: 11px"
                  class="text-left mb-10"
                >
                  {{ subscriptionStore.subscriptionMessage }}
                </div>
              </div>

              <div class="d-flex flex-column ga-7">
                <div
                  v-for="sub_feature in plans[0].features[0].sub_features"
                  class="d-flex justify-space-between align-center"
                >
                  <div class="d-flex align-center ga-sm-2 ga-1 text-left">
                    <img
                      class="w-1 h-1"
                      v-if="sub_feature.availlable"
                      src="@/assets/auth/check_new.svg"
                    />
                    <img class="w-1 h-1" v-else src="@/assets/close.svg" />
                    <span class="font-weight-bold" style="color: #004838">{{
                      sub_feature.title
                    }}</span>
                  </div>
                  <div
                    v-if="sub_feature.isSoon"
                    class="text-secondary border-opacity-100 border border-secondary px-sm-4 px-2 py-sm-1 rounded-pill"
                  >
                    {{ t('practitionerPresentation.soon') }}
                  </div>
                </div>
              </div>
            </div>
            <template v-else>
              <!-- price block  -->
              <div class="my-sm-8 my-sm-3 my-2">
                <div class="d-flex align-center">
                  <div class="price font-weight-bold">
                    <span class="price-integer"
                      >€{{ priceInteger(plans[0].price) }}</span
                    ><span v-if="plans[0].price" class="price-decimal">{{
                      decimalPrice(plans[0].price)
                    }}</span>
                  </div>
                  <div class="d-flex" v-if="plans[0].discount">
                    <div class="d-flex flex-column text-center mr-1">
                      <div
                        v-if="plans[0].old_price"
                        class="custom-strikethrough"
                      >
                        €{{ plans[0].old_price }}
                      </div>
                      <div class="taxe">
                        HT
                        {{ plans[0].id == 'annual' ? '' : '/Mois' }}
                      </div>
                    </div>
                    <div
                      class="rounded-pill align-self-start py-0.5 px-1 text-white"
                      style="background-color: #ff5a43"
                    >
                      -{{ plans[0].discount }}%
                    </div>
                  </div>
                  <div class="align-self-center pa-0" v-else>HT</div>
                </div>
                <div style="color: #616161; font-size: 11px" class="text-left">
                  {{ message }}
                </div>
              </div>

              <!-- features details block  -->
              <div
                class="d-flex flex-column mb-sm-5 mb-3 text-left"
                v-for="feature in plans[0].features"
              >
                <h4 class="mb-2 text-dark-green">{{ feature.title }}</h4>
                <div class="d-flex flex-column ga-1">
                  <div
                    v-for="sub_feature in feature.sub_features"
                    class="d-flex justify-space-between align-center"
                  >
                    <div class="d-flex align-center ga-sm-2 ga-1">
                      <img
                        v-if="sub_feature.availlable"
                        src="@/assets/auth/check_new.svg"
                      />
                      <img v-else src="@/assets/close.svg" />
                      <span style="color: #616161">{{
                        sub_feature.title
                      }}</span>
                    </div>
                    <div
                      v-if="sub_feature.isSoon"
                      class="text-secondary border-opacity-100 border border-secondary px-sm-4 px-2 py-sm-1 rounded-pill"
                    >
                      {{ t('practitionerPresentation.soon') }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <div class="d-flex flex-column ga-sm-5 ga-3">
            <v-btn
              v-if="false"
              @click="subscriptionStore.toggleSubscription"
              block
              color="secondary"
              >{{ t('change_subscription') }}</v-btn
            >
            <div
              @click="displayDetail = !displayDetail"
              class="text-secondary cursor-pointer text-decoration-underline font-weight-bold text-center"
            >
              {{
                displayDetail
                  ? t('practitionerPresentation.less_detail')
                  : t('practitionerPresentation.more_details')
              }}
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div
        v-if="plans[0].id === 'monthly'"
        v-html="plans[0].description"
        class="pa-1 w-75 mx-auto text-white position-absolute d-flex justify-center text-center align-center d-inline-flex rounded-pill"
        style="font-size: 11px; top: -22px; left: 5px; right: 5px"
        :style="{ backgroundColor: plans[0].bgDesc }"
      ></div>
    </div>
    <div v-else class="d-md-flex ga-md-5" style="position: relative">
      <div v-for="plan in plans" class="position-relative mb-md-0 mb-10">
        <v-card
          elavation="0"
          max-width="500"
          min-width="300"
          color="white"
          :class="
            plan.id == 'cabinet' ? 'border-gray-ligth' : 'border-secondary'
          "
          class="border border-md border-opacity-100 py-sm-8 pt-10 px-sm-10 rounded-xl flat"
          style="box-shadow: none !important"
        >
          <v-card-text>
            <div class="d-flex ga-sm-2 ga-1">
              <div
                :class="plan.bgIconColor"
                class="d-flex align-center justify-center pa-sm-5 rounded-lg"
                style="width: 100px; height: 100px"
              >
                <img :src="plan.icon" />
              </div>
              <div class="d-flex flex-column">
                <h3 class="mb-2" style="font-size: 16px; color: #004838">
                  {{ plan.title }}
                </h3>
                <p
                  v-if="plan.subTitle1"
                  class="d-flex align-center ga-1 mb-1"
                  style="font-size: 13px"
                >
                  <img class="w-5" :src="plan.subTitle1.icon" />
                  <span>{{ plan.subTitle1.title }}</span>
                </p>
                <p
                  v-if="plan.subTitle2"
                  class="d-flex align-center ga-1"
                  style="font-size: 13px"
                >
                  <img class="w-5" :src="plan.subTitle2.icon" />
                  <span>{{ plan.subTitle2.title }}</span>
                </p>
              </div>
            </div>

            <template v-if="plan.id !== 'cabinet'">
              <!-- display default praticien feature -->
              <div class="my-sm-4 my-2" v-if="!displayDetail">
                <div class="my-sm-3 my-2">
                  <div class="d-flex align-center">
                    <div class="price font-weight-bold">
                      <span class="price-integer"
                        >€{{ priceInteger(plan.price) }}</span
                      ><span v-if="plan.price" class="price-decimal">{{
                        decimalPrice(plan.price)
                      }}</span>
                    </div>
                    <div class="d-flex" v-if="plan.discount">
                      <div class="d-flex flex-column text-center mr-1">
                        <div v-if="plan.old_price" class="custom-strikethrough">
                          €{{ plan.old_price }}
                        </div>
                        <div class="taxe">
                          HT
                          {{ plan.id == 'annual' ? '' : '/Mois' }}
                        </div>
                      </div>
                      <div
                        class="rounded-pill align-self-start py-0.5 px-1 text-white"
                        style="background-color: #ff5a43"
                      >
                        -{{ plan.discount }}%
                      </div>
                    </div>
                    <div class="align-self-center pa-0" v-else>HT</div>
                  </div>
                  <div style="color: #616161" class="text-left">
                    {{ plan.message }}
                  </div>
                </div>

                <div class="d-flex flex-column ga-7">
                  <div
                    v-for="sub_feature in plan.features[0].sub_features"
                    class="d-flex justify-space-between align-center"
                  >
                    <div class="d-flex align-center ga-sm-2 ga-1 text-left">
                      <img
                        class="w-1 h-1"
                        v-if="sub_feature.availlable"
                        src="@/assets/auth/check_new.svg"
                      />
                      <img class="w-1 h-1" v-else src="@/assets/close.svg" />
                      <span class="font-weight-bold" style="color: #004838">{{
                        sub_feature.title
                      }}</span>
                    </div>
                    <div
                      v-if="sub_feature.isSoon"
                      class="text-secondary border-opacity-100 border border-secondary px-sm-4 px-2 py-sm-1 rounded-pill"
                    >
                      {{ t('practitionerPresentation.soon') }}
                    </div>
                  </div>
                </div>
              </div>
              <template v-else>
                <!-- price block  -->
                <div class="my-sm-8 my-sm-3 my-2">
                  <div class="d-flex align-center">
                    <div class="price font-weight-bold">
                      <span class="price-integer"
                        >€{{ priceInteger(plan.price) }}</span
                      ><span v-if="plan.price" class="price-decimal">{{
                        decimalPrice(plan.price)
                      }}</span>
                    </div>
                    <div class="d-flex" v-if="plan.discount">
                      <div class="d-flex flex-column text-center mr-1">
                        <div v-if="plan.old_price" class="custom-strikethrough">
                          €{{ plan.old_price }}
                        </div>
                        <div class="taxe">
                          HT
                          {{ plan.id == 'annual' ? '' : '/Mois' }}
                        </div>
                      </div>
                      <div
                        class="rounded-pill align-self-start py-0.5 px-1 text-white"
                        style="background-color: #ff5a43"
                      >
                        -{{ plan.discount }}%
                      </div>
                    </div>
                    <div class="align-self-center pa-0" v-else>HT</div>
                  </div>
                  <div style="color: #616161" class="text-left">
                    {{ plan.message }}
                  </div>
                </div>

                <!-- features details block  -->
                <div
                  class="d-flex flex-column mb-sm-5 mb-3 text-left"
                  v-for="feature in plan.features"
                >
                  <h4 class="mb-2 text-dark-green">{{ feature.title }}</h4>
                  <div class="d-flex flex-column ga-1">
                    <div
                      v-for="sub_feature in feature.sub_features"
                      class="d-flex justify-space-between align-center"
                    >
                      <div class="d-flex align-center ga-sm-2 ga-1">
                        <img
                          v-if="sub_feature.availlable"
                          src="@/assets/auth/check_new.svg"
                        />
                        <img v-else src="@/assets/close.svg" />
                        <span style="color: #616161">{{
                          sub_feature.title
                        }}</span>
                      </div>
                      <div
                        v-if="sub_feature.isSoon"
                        class="text-secondary border-opacity-100 border border-secondary px-sm-4 px-2 py-sm-1 rounded-pill"
                      >
                        {{ t('practitionerPresentation.soon') }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>

            <img
              v-else
              style="max-height: 400px; width: 100%"
              src="@/assets/practitioner-presentation\default-features.png"
            />
            <div class="d-flex flex-column ga-sm-5 ga-3">
              <v-btn
                @click="redirect(plan.id)"
                block
                :color="plan.id == 'cabinet' ? '#E0E0E0' : 'secondary'"
                :class="plan.id == 'cabinet' ? 'text-white' : ''"
                >{{ t('practitionerPresentation.start_now_btn') }}</v-btn
              >
              <template v-if="plan.id == 'cabinet'">
                <div
                  class="text-disabled cursor-pointer text-decoration-underline"
                >
                  {{ t('practitionerPresentation.more_details') }}
                </div>
              </template>
              <template v-else>
                <div
                  @click="displayDetail = !displayDetail"
                  class="text-secondary cursor-pointer text-decoration-underline"
                >
                  {{
                    displayDetail
                      ? t('practitionerPresentation.less_detail')
                      : t('practitionerPresentation.more_details')
                  }}
                </div>
              </template>
            </div>
          </v-card-text>
        </v-card>
        <div
          v-html="plan.description"
          :class="plan.id == 'cabinet' ? 'pa-3 w-sm-50' : 'w-sm-75 pa-1 px-2'"
          class="text-white position-absolute d-flex justify-center align-center d-inline-flex mx-auto rounded-pill"
          style="font-size: 14px; top: -22px; left: 5px; right: 5px"
          :style="{ backgroundColor: plan.bgDesc }"
        ></div>
      </div>
    </div>
  </v-expand-transition>
</template>

<style>
.custom-card {
  box-shadow: none !important;
}
.price {
  font-size: 40px;
  color: #004838;
}
.taxe {
  font-size: 11px;
  color: #616161;
}

.price-decimal {
  font-size: 20px;
  vertical-align: super;
}

.custom-strikethrough {
  position: relative;
  color: inherit;
}

.custom-strikethrough::after {
  content: '';
  position: absolute;
  left: 20%;
  right: 20%;
  top: 40%;
  border-top: 2px solid #ff5a43;
}
</style>
