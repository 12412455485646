<template>
  <v-container class="pa-0">
    <v-card
      :color="color"
      class="rounded-xl pa-0 flat"
      style="box-shadow: none !important"
    >
      <v-card-title
        v-if="subscriptionStore.planChoise.id === 'monthly'"
        class="offer-title text-center d-flex justify-center"
        v-html="subscriptionStore.planChoise.description"
      ></v-card-title>
      <v-card-text class="pa-4">
        <div class="d-flex ga-2">
          <div
            class="d-flex align-center justify-center pa-5 bg-secondary rounded-lg"
            style="width: 52px; height: 54px"
          >
            <img
              style="width: 18.14px; height: 26.39px"
              :src="subscriptionStore.planChoise.icon"
            />
          </div>
          <div class="d-flex flex-column">
            <h3 class="choice-title">
              {{ subscriptionStore.planChoise.title }}
            </h3>
            <p
              v-if="subscriptionStore.planChoise.subTitle1"
              class="d-flex align-center ga-1 mb-1"
              style="font-size: 11px"
            >
              <img
                style="width: 14px; height: 14px"
                :src="subscriptionStore.planChoise.subTitle1.icon"
              />
              <span>{{ subscriptionStore.planChoise.subTitle1.title }}</span>
            </p>
            <p
              v-if="subscriptionStore.planChoise.subTitle2"
              class="d-flex align-center ga-1"
              style="font-size: 11px"
            >
              <img
                style="width: 14px; height: 14px"
                :src="subscriptionStore.planChoise.subTitle2.icon"
              />
              <span>{{ subscriptionStore.planChoise.subTitle2.title }}</span>
            </p>
          </div>
        </div>
        <div class="d-flex align-center ga-3 mt-5 mb-3">
          <div class="price font-weight-bold">
            <span class="price-integer">€{{ priceInteger }}</span
            ><span v-if="decimalPrice" class="price-decimal">{{
              decimalPrice
            }}</span>
          </div>
          <div
            class="d-flex"
            v-if="
              subscriptionStore.planChoise.discount &&
              subscriptionStore.hasDiscount
            "
          >
            <div class="d-flex flex-column text-center mr-1">
              <div
                v-if="subscriptionStore.planChoise.old_price"
                class="custom-strikethrough"
              >
                €{{ subscriptionStore.planChoise.old_price }}
              </div>
              <div class="taxe">
                H.T
                {{ subscriptionStore.planChoise.id == 'annual' ? '' : '/Mois' }}
              </div>
            </div>
            <div
              class="rounded-pill align-self-start py-0.5 px-1 text-white"
              style="background-color: #ff5a43"
            >
              -{{ subscriptionStore.planChoise.discount }}%
            </div>
          </div>
          <div class="align-self-center pa-0" v-else>HT</div>
        </div>
        <div style="font-size: 11px">{{ message }}</div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import { useUserTvaStore } from '@/store/tva/userTvaStore'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useDisplay, useTheme } from 'vuetify'

const { color } = defineProps({
  color: {
    type: String,
    default: 'white',
  },
})

const { mdAndDown, mobile } = useDisplay()

const subscriptionStore = useSubscriptionStore()
const userTva = useUserTvaStore()
const route = useRoute()
const { t } = useI18n()

const priceInteger = computed(() => {
  if (subscriptionStore.hasDiscount) {
    return Math.floor(subscriptionStore.planChoise.price)
  }
  return Math.floor(subscriptionStore.planChoise.old_price)
})

const decimalPrice = computed(() => {
  let decimalPart = ''

  if (subscriptionStore.hasDiscount) {
    decimalPart = (subscriptionStore.planChoise.price % 1)
      .toFixed(2)
      .substring(1)
  } else {
    decimalPart = (subscriptionStore.planChoise.old_price % 1)
      .toFixed(2)
      .substring(1)
  }
  return decimalPart === '.00' ? '' : decimalPart
})

const price = computed(() => {
  let discount = 0

  if (subscriptionStore.hasDiscount) {
    discount = subscriptionStore.planChoise.discount
  }

  const finalPrice =
    userTva.applyTva(
      subscriptionStore.planChoise.old_price,
      subscriptionStore.planChoise.tva_percent,
      discount,
    ) ?? 0
  return finalPrice
})

const message = computed(() => {
  const tva = subscriptionStore.planChoise.tva_percent ?? 0
  let msg = ''

  if (
    subscriptionStore.planChoise.id == 'lifetime' ||
    subscriptionStore.planChoise.id == 'life'
  ) {
    msg = `TVA = ${tva}%  soit ${price.value}€ ${tva ? 'TTC' : 'HT'} facturé en une seule fois.`
  } else {
    msg = `TVA = ${tva}%  soit ${price.value}€ ${tva ? 'TTC' : 'HT'} facturé ${subscriptionStore.planChoise.id == 'annual' ? 'annuellement' : 'mensuellement'}.`
  }

  return msg
})
</script>

<style scoped lang="scss">
.offer-title {
  font-size: 12px !important;
  background-color: #004838;
  color: white;
  white-space: normal; /* Permet de passer à la ligne si le texte est trop long */
  word-wrap: break-word; /* Force le texte à s'emballer au-delà de la largeur */
  max-width: 100%;
}

.price {
  font-size: 32px;
  color: #004838;
}
.taxe {
  font-size: 10px;
  color: #616161;
}

.price-decimal {
  font-size: 20px;
  vertical-align: super;
}

.custom-strikethrough {
  position: relative;
  color: inherit;
  font-size: 14px;
}

.custom-strikethrough::after {
  content: '';
  position: absolute;
  left: 20%;
  right: 20%;
  top: 40%;
  border-top: 2px solid #ff5a43;
}

.choice-title {
  font-size: 14px;
  color: #004838;
}

@media (max-width: 599px) {
  .choice-title {
    font-size: 12px;
  }
}
</style>
